import { API_START, API_SUCCESS, API_ERROR } from "./types";
import { api } from "../../api/instance";

const apiStartAction = (key) => ({ type: API_START, payload: { key } })
const apiSuccessAction = (key, data) => ({ type: API_SUCCESS, payload: { key, data } })
const apiErrorAction = (key, error) => ({ type: API_ERROR, payload: { key, error } })


export const apiCallAction = (key, subUrl, { method = "GET", data = {} } = {}) => {
    
    return dispatch => {
        dispatch(apiStartAction(key))
        return api.request({ method, url: subUrl, data }).then(resp => {
            dispatch(apiSuccessAction(key, resp.data))
        }).catch(e => {
            dispatch(apiErrorAction(key, e))
        })
    }
}