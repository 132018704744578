import React from 'react'
import { Box, useColorMode } from '@chakra-ui/core'

export default function Card({ children, ...props }) {

    const { colorMode } = useColorMode();
    return (
        <Box bg={colorMode === "light" ? "blackAlpha.300" : "blackAlpha.500"} padding="2" {...props} >
            {children}

        </Box>
    )
}
