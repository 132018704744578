import { api } from "./instance";

// Join fields
const getAll = (entity, query) => {
    return api.get(`${entity}${query}`).then(resp => resp.data)
}


// Join Fields
const getOne = (entity, id) => {
    return api.get(`${entity}/${id}`).then(resp => resp.data)
}

const create = (entity, data) => {
    // console.log()
    return api.post(`${entity}`, data).then(resp => resp.data)
}

const update = (entity, data) => {
    const { id } = data
    return api.patch(`${entity}/${id}`, data).then(resp => resp.data)
}

const deleteEntry = (entity, id) => {
    return api.delete(`${entity}/${id}`).then(resp => resp.data)
}


export default {
    getAll,
    getOne,
    create,
    update,
    deleteEntry,
}
