import React from "react";
import crud from "../api/crud";
import DealerScreen from "../screens/DealersScreen";
import { getDataSource } from "../api/tableFilter";
import { useAccess } from "../reducers/api/hooks";
import { send } from "../api/calls";
import { useToast } from "@chakra-ui/core";
import { successToast, errorToast } from "../components/Toasts";

export default function DealerContainer() {
  //   const { data, success, loading, refresh } = useApi("services", "services");
  const dataSource = getDataSource("dealer");

  const handleCreate = data => {
    console.log(data)
    // return crud.create("dealer", data);
    // const user = {
    //   "email": "notificationContenting",
    //   "firstName": "notificationContenting",
    //   "lastName": "notificationContenting",
    //   "password": "notificationContenting",
    //   "roles": [
    //     "notificationContenting"
    //   ]
    // }

    return crud.create("user", { ...data, roles: ["dealer"] })
      .then(res => res.id)
      .then(id => crud.create("dealer", { ...data, user: id, name: data.firstName }))
  };


  const handleDelete = items => {
    const promises = items
      .map(item => crud.deleteEntry("dealer", item.id));
    return Promise.all(promises);
  };

  const handleEdit = data => {
    const { id: userId } = data.user

    return crud.update("dealer", data)
      .then(() => crud.update("user", { ...data, id: userId }))
  };

  const searchBrands = txt => {
    const mapItems = item => ({ value: item.id, label: item.name });
    return crud
      .getAll("brand", txt ? `?filter=name||cont||${txt}` : "")
      .then(data => data)
      .then(items => items.map(mapItems));
  };

  const isAdmin = useAccess("admin")

  const toast = useToast()
  const onSuccess = (eventName) => () => {
      toast(successToast(`notification ${eventName}`, `Success`))
  }
  const onError = eventName => () => {
      toast(errorToast(`$notification ${eventName} Failed`, `Error`))
  }
  
  const [isOpen, setIsOpen] = React.useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  
  const handleNotification = data => {
    console.log(data)
    // const isOpen = true;
    const notificationContent = {}
    notificationContent.title = data.title
    notificationContent.content = data.content
    notificationContent.users = data.selectedItems.map(item => item.user.id)
    send(notificationContent)
    .then(onSuccess("Send"))
    .catch(onError("Send"))

    setIsOpen(false)
  }

  const handleActivate = (items) => {
    const activate = id => crud.update("user", { active: true, id })
    const promises = items.map(item => item.user.id).map(activate)
    return Promise.all(promises)
  }

  const handleDeactivate = (items) => {
    const deactivate = id => crud.update("user", { active: false, id })
    const promises = items.map(item => item.user.id).map(deactivate)
    return Promise.all(promises)
  }

  const shouldDisplay = (check) => items => {
    return items.length === 1 && check(items[0]) ? undefined : "none"
}

  const customActions = [
    { onClick: handleActivate, title: "Activate", getDisplay: shouldDisplay(item => item.user.active !== true) },
    { onClick: handleDeactivate, title: "Deactivate", getDisplay: shouldDisplay(item => item.user.active !== false) },
  ]

  return (
    <DealerScreen
      dataSource={dataSource}
      onCreate={isAdmin ? handleCreate : undefined}
      onDelete={isAdmin ? handleDelete : undefined}
      onEdit={isAdmin ? handleEdit : undefined}
      onSendNotification={handleNotification}
      isOpen={isOpen}
      open={open}
      close={close}
      customActions={customActions}
      //   onRefresh={refresh}
      brandResolver={searchBrands} 
    />
  );
}