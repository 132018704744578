import React from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Table from '../components/Table.js';
import CrudPage from "../components/CrudPage.js";
import { formatDateTime } from "../lib/datetime.js";
import { Code, Heading } from "@chakra-ui/core";
import Card from "../components/Card.js";
import Parameter from "../components/Parameter.js";

export default function ChangeRecordScreen({ onRefresh, dataSource }) {

  return (
    <CrudPage
      onRefresh={onRefresh}
      renderTable={props => <RecordChangeTable {...props} dataSource={dataSource} />}
      renderView={props => <ChangeRecordView {...props} />}
      title="Change-Records"
    />
  )
}

function RecordChangeTable({ dataSource, onSelect }) {
  const RecordChangeTableOptions = {
    columnDefs: [
      {
        headerName: "ID",
        field: "id",
        sortable: true,
        sort: "desc",
        filter: true
      },
      {
        headerName: "User",
        field: "user.firstName",
        // filter: true
      },
      {
        headerName: "ChangeDescription",
        field: "action",
        filter: true
      },
      {
        headerName: "Time",
        field: "time",
        sortable: true,
        // filter: "agDateColumnFilter",
        cellRenderer: ({ value }) => {
          return formatDateTime(value)
        }
      }
    ],
    // rowData: [
    //   {
    //     changeId: "1",
    //     userId: "phone",
    //     changeDescription: "description",
    //     timeStamp: "time"
    //   }
    // ]
  };
  console.log(dataSource)
  return (
    <Table
      columnDefs={RecordChangeTableOptions.columnDefs}
      dataSource={dataSource}
      onChangeSelction={onSelect}
    ></Table>
  )
}


const ChangeRecordView = ({ data }) => {
  return <div style={{ width: '100%' }}>
    <Card>
      <Heading size="md">Details</Heading>
      <Parameter title="User" value={data.user.firstName} />
      <Code>
        {data.details}
      </Code>

    </Card>
  </div>
}