import React, { useState } from 'react'
import {
  Heading,
  Button,
  ButtonGroup,
  Stack,
} from '@chakra-ui/core'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Table from '../components/Table.js';
import CrudPage from '../components/CrudPage.js';
import Parameter from '../components/Parameter.js';
import { formatDateTime } from '../lib/datetime.js';
import Card from '../components/Card.js';

export default function OrderScreen({ dataSource, customActions, onToggleArchived, showArchived }) {

  const [, setSelectedItem] = useState(null)
  const handleSelection = (items) => {
    if (items.length === 1) {
      setSelectedItem(items[0])
    }
  }

  return (
    <CrudPage
      title={showArchived ? "Archived Orders" : "Orders"}
      renderTable={props => <OrderScreenTable {...props} dataSource={dataSource} />}
      renderView={props => <OrderView {...props} />}
      onSelectionChange={handleSelection}
      customActions={customActions}
      renderActions={() => <ActionButtons onToggleArchived={onToggleArchived} showArchived={showArchived} />}
    >

    </CrudPage>

    // <div>
    //   <Box roundedTop="20px" bg="purple.600" w="100%" p={4} color="white">
    //     <Heading fontSize="40px" pt={4} pd={4}>
    //       Orders
    //         </Heading>
    //   </Box>
    //   <OrderScreenTable />
    // </div>
  )
}

const ActionButtons = ({ onToggleArchived, showArchived }) => {
  return <ButtonGroup>
    <Button onClick={onToggleArchived}>
      {showArchived ? "Show active orders" : "Show archived orders"}
    </Button>
  </ButtonGroup>
}

function OrderScreenTable({ dataSource, onSelect, }) {
  const OrderTableOptions = {
    columnDefs: [{
      headerName: "OrderId",
      field: "id",
      sort: 'desc'
    },
    {
      headerName: "Dealer",
      field: "dealer.name"
    },
    {
      headerName: "Created Date",
      field: "createdDate",
      sortable: true,
      cellRenderer: ({ value }) => {
        return formatDateTime(value)
      }
    },
    {
      headerName: "State",
      field: "state",
      cellRenderer: ({ value }) => {
        const map = { accepted: "Accepted", "rejected": "Rejected", none: "Pending" }
        return map[value]
      }
    },],

  }
  const rowStyles = ({ data ={user:{}} }) => {
    const styles = {}
    if (data.state === "accepted") {
      styles["color"] = "green"
    }
    if (data.state === "rejected") {
      styles["color"] = "red"
    }
    if (data.archived) {
      styles["color"] = "gray"
    }

    return styles
  }
  return (
    <Table
      columnDefs={OrderTableOptions.columnDefs}
      dataSource={dataSource}
      onChangeSelction={onSelect}
      rowStyles={rowStyles}
    ></Table>
  )
}


const OrderView = ({ data }) => {

  const columnDefs = [
    {
      headerName: "Product Id",
      field: "product.id",
      resizable: true
    },
    {
      headerName: "Brand",
      field: "product.brand.name",
      resizable: true,  
      cellRenderer: (data) => {
        // const getCircularReplacer = () => {
        //   const seen = new WeakSet();
        //   return (key, value) => {
        //     if (typeof value === "object" && value !== null) {
        //       if (seen.has(value)) {
        //         return;
        //       }
        //       seen.add(value);
        //     }
        //     return value;
        //   };
        // };
        // alert(JSON.stringify(data, getCircularReplacer()))
        if (data.data.product.isPromotion === true) {
          return ""
        }
        else {
          return data.data.product.brand.name
        }
      }
    },
    {
      headerName: "Name",
      field: "product.name",
      resizable: true
    },
    {
      headerName: "Price",
      field: "price",
      resizable: true
    },
    {
      headerName: "Count",
      field: "count",
      resizable: true
    },
    {
      headerName: "Payment Method",
      field: "paymentMethod",
      resizable: true
    },
    {
      headerName: "Bulk Count",
      field: "bulkCount",
      resizable: true
    }
  ]
  
  const rowStyles = ({ data ={user:{}} }) => (data.product.isPromotion ? { color: "blue" } : {});
  return <div style={{ width: '100%' }}>
    <Card>
      <Heading size="md">Order Details</Heading>
      {/* dkla */}
      <Stack spacing="5" padding="2">
        <Parameter title="ID" value={data.id} />
        <Parameter title="Dealer" value={data.dealer.name} />
        <Parameter title="Created Date" value={formatDateTime(data.createdDate)} />
      </Stack>
      {/* <StatLabel>Dealer</StatLabel>
      <StatNumber>{data.dealer.name}</StatNumber> */}
      <Table height={300} columnDefs={columnDefs} rowData={data.orderProducts} paginationPageSize={5} rowStyles={rowStyles} />
    </Card>

    {/* {JSON.stringify(data)} */}
  </div>
}

