import React, { useState, useEffect } from "react";
import {
  Heading,
  Box,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  Button,
  ButtonGroup,
  PopoverTrigger,
  Popover,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverContent,
  PopoverBody,
  PopoverFooter
} from "@chakra-ui/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Table from "../components/Table.js";
import CrudPage from "../components/CrudPage.js";
import Parameter from "../components/Parameter.js";
import { useField, useForm } from "react-final-form-hooks";
import {
  isRequiredValidator,
  isPhoneNumberValidator,
  isEmailValidator
} from "../lib/validators.js";
import Card from "../components/Card.js";

export default function DealerScreen({
  dataSource,
  onRefresh,
  onCreate,
  onEdit,
  open,
  onDelete,
  onSendNotification,
  isOpen,
  close,
  customActions
}) {
  return (
    <CrudPage
      onCreate={onCreate}
      onRefresh={onRefresh}
      onEdit={onEdit}
      onDelete={onDelete}
      renderActions={props => <NPopover {...props} onSendNotification={onSendNotification} isOpen={isOpen} open={open} close={close} />}
      renderForm={props => <DealerForm {...props} />}
      renderView={props => <DealerView {...props} />}
      customActions={customActions}
      // renderForm={props => <DealerForm {...props} />}
      renderTable={props => (
        <DealerScreenTable {...props} dataSource={dataSource} />
      )}
      title="Dealers"
    />
  );
}

const NPopover = ({onSendNotification, isOpen, open, close, selectedItems, data = {} }) => {

  const initialFocusRef = React.useRef();

  const onSubmitProcess = data => {
    return onSendNotification({...data, selectedItems});
  };

  const { form, handleSubmit, } = useForm({
    onSubmit: onSubmitProcess
  });

  const title = useField("title", form, isRequiredValidator)
  const content = useField("content", form, isRequiredValidator)

  return (
  <Popover 
    placement="top-start" 
    closeOnBlur='true' 
    initialFocusRef={initialFocusRef} 
    isOpen={isOpen}
    onOpen={open}
    onClose={close}
  >
    <PopoverTrigger>
      <Button>Compose Notification</Button>
    </PopoverTrigger>
    <PopoverContent zIndex={4} bg="blue.800" color="white">
      <PopoverHeader fontWeight="semibold">Notification</PopoverHeader>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverBody>
        <FormControl>
          <FormLabel htmlFor="title">Title</FormLabel>
          <Input {...title.input} color="black" id="title" ref={initialFocusRef}/>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="content">Content</FormLabel>
          <Input {...content.input} color="black" id="content"></Input>
        </FormControl>
      </PopoverBody>
      <PopoverFooter>
        <Button  variantColor="green" onClick={handleSubmit}>Send</Button>
      </PopoverFooter>
    </PopoverContent>
  </Popover>
  )
}

function DealerScreenTable({ dataSource, onSelect }) {
  const DealerTableOptions = {
    columnDefs: [
      {
        headerName: "DealerId",
        field: "id"
      },
      {
        headerName: "name",
        field: "name"
      },
      {
        headerName: "Address",
        field: "address"
      },
      {
        headerName: "Contact No",
        field: "contactNo"
      }
    ],
  };
  const rowStyles = ({ data ={user:{}} }) => (data.user.active ? {} : { color: "grey" });
  return (
    <Table
      onChangeSelction={onSelect}
      dataSource={dataSource}
      columnDefs={DealerTableOptions.columnDefs}
      rowStyles={rowStyles}
    ></Table>
  );
}

const DealerView = ({ data }) => {
  return (
    <div style={{ width: "100%" }}>
      <Card>
        <Heading size="md">Dealer Details</Heading>
        {/* dkla */}
        <Stack spacing="5" padding="2">
          <Parameter title="ID" value={data.id} />
          <Parameter title="Dealer" value={data.name} />
          <Parameter title="Email" value={data.user.email} />
        </Stack>
        {/* <StatLabel>Dealer</StatLabel>
      <StatNumber>{data.dealer.name}</StatNumber> */}
        {/* <Table height={300} columnDefs={columnDefs} rowData={data.orderProducts} /> */}
      </Card>

      {/* {JSON.stringify(data)} */}
    </div>
  );
};

function DealerForm({ onSubmit, onCancel, data = {}, isEdit = false }) {
  const [hidePasswordEntry, setHidePasswordEntry] = useState(isEdit);
  const togglePasswordEntry = () => {
    setHidePasswordEntry(v => !v);
  };

  // On submit hide password entry when update if password field is disabled
  const onSubmitProcess = data => {
    if (hidePasswordEntry) {
      return onSubmit({ ...data, password: undefined });
    }
    return onSubmit(data);
  };

  const processBeforeSubmit = data => {
    return onSubmit({ ...data });
  };

  const [initialValues, setInitialValues] = useState(data);
  useEffect(() => {
    console.log(data);
    setInitialValues({ ...data.user, ...data, password: "" });
  }, [data]);

  const { form, handleSubmit, pristine, submitting } = useForm({
    onSubmit: processBeforeSubmit,
    onSubmitProcess,
    initialValues
  });

  const firstName = useField("firstName", form, isRequiredValidator);
  const lastName = useField("lastName", form, isRequiredValidator);
  const contactNo = useField("contactNo", form, isPhoneNumberValidator);
  const address = useField("address", form, isRequiredValidator);
  const email = useField("email", form, isEmailValidator);
  const password = useField(
    "password",
    form,
    hidePasswordEntry ? undefined : isRequiredValidator
  );

  return (
    <>
      <Stack spacing="24px">
        <FormControl isInvalid={firstName.meta.error} isRequired>
          <FormLabel htmlFor="dealerName">First Name</FormLabel>
          <Input
            {...firstName.input}
            id="dealerName"
            placeholder="Please enter Dealer First Name"
          />
          {firstName.meta.touched && firstName.meta.error && (
            <FormErrorMessage>{firstName.meta.error}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input {...lastName.input} placeholder="Please enter Last Name " />
        </FormControl>
        <FormControl isRequired isInvalid={email.meta.error}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            {...email.input}
            placeholder="Please enter username"
          />
          {email.meta.touched && email.meta.error && (
            <FormErrorMessage>{email.meta.error}</FormErrorMessage> 
          )}
        </FormControl>
        {!hidePasswordEntry ? (
          <FormControl isRequired>
            <FormLabel htmlFor="password">Password</FormLabel>
            <InputGroup>
              <Input
                type="password"
                id="password"
                {...password.input}
                placeholder="Please enter password"
                rounded="0"
              />
            </InputGroup>
          </FormControl>
        ) : (
          <></>
        )}
        {isEdit ? (
          <Button onClick={togglePasswordEntry}>
            {" "}
            {hidePasswordEntry ? "Change Password" : "Don't change password"}
          </Button>
        ) : (
          <></>
        )}
        <FormControl isInvalid={contactNo.meta.error} isRequired>
          <FormLabel htmlFor="contactNo">Contact Number</FormLabel>
          <Input
            {...contactNo.input}
            id="contactNo"
            placeholder="Please enter Contact No."
          />
          {contactNo.meta.touched && contactNo.meta.error && (
            <FormErrorMessage>{contactNo.meta.error}</FormErrorMessage> 
          )}
        </FormControl>
        <Box>
          <FormLabel htmlFor="address">Address</FormLabel>
          <Input
            {...address.input}
            id="address"
            placeholder="Please enter Address"
          />
        </Box>
      </Stack>
      <ButtonGroup mt="2">
        <Button variant="outline" mr={3} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variantColor="blue"
          isDisabled={pristine || submitting}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </ButtonGroup>
    </>
  );
}
