// using ES6 modules
import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { Box } from '@chakra-ui/core';
import LoginContainer from './LoginContainer';
import OrdersContainer from './OrdersContainer';
import CustomDrawer from '../components/CustomDrawer';
import { logoutAction } from '../reducers/auth/actions';
import UsersContainer from './UsersContainer';
import ProductContainer from './ProductContainer';
import BrandContainer from './BrandContainer';
import ServicesContainer from './ServicesContainer';
import ServiceRequestContainer from './ServiceRequestContainer';
import DealersContainer from './DealersContainer';
import HomeContainer from './HomeContainer';
import ChangeRecordsContainer from './ChangeRecordsContainer';
import PromotionsContainer from './PromotionsContainer';
import { useAccess } from '../reducers/api/hooks';
import { 
    FaUser, 
    FaClipboardList, 
    FaMobileAlt, 
    FaClipboard,
    FaHome,
    FaTags,
    FaUserTag,
    FaBook,
    FaStar
} from 'react-icons/fa'
import { IoIosBuild } from 'react-icons/io'

export default function Navigator() {
    const dispatch = useDispatch()
    const { user, loggedIn } = useSelector(s => s.auth)

    const handleLogout = () => {
        dispatch(logoutAction())
    }

    const usersName = user ? user.firstName : ""
    const isAdmin = useAccess("admin")
    const isViewer = useAccess("viewer")

    const isValidUser = (isAdmin || isViewer) && loggedIn

    return (
        <Router>
            <Box mxFaStar="auto">
                <Box>
                    <CustomDrawer links={isValidUser ? routes : []} onLogout={handleLogout} loggedIn={loggedIn} name={usersName} />
                    {isValidUser &&

                        <>

                            <Route exact path="/" component={HomeContainer} />
                            <Route path="/orders/" component={OrdersContainer} />
                            <Route path="/serviceRequests/" component={ServiceRequestContainer} />
                            <Route path="/users/" component={UsersContainer} />
                            <Route path="/dealers/" component={DealersContainer} />
                            <Route path="/promotions/" component={PromotionsContainer} />
                            <Route path="/products/" component={ProductContainer} />
                            <Route path="/brands/" component={BrandContainer} />  
                            <Route path="/services/" component={ServicesContainer} />
                            <Route path="/changeRecords/" component={ChangeRecordsContainer} />
                        </>
                    }
                    {!loggedIn && <LoginContainer />}
                    {/* {JSON.stringify(api)} */}
                </Box>
            </Box>
        </Router>
    )
}


const routes = [
    { name: "Home", link: "", icon: FaHome },
   
    { name: "Orders", link: "orders", icon: FaClipboardList },
    { name: "Service Requests", link: "serviceRequests", icon: FaClipboard },
    { name: "Users", link: "users", icon: FaUser },
    { name: "Dealers", link: "dealers", icon: FaUserTag },
    { name: "Promotions", link: "promotions", icon: FaTags },
    { name: "Products", link: "products", icon: FaMobileAlt },
    { name: "Brands", link: "brands", icon: FaStar },
    { name: "Services", link: "services", icon: IoIosBuild }, 
    { name: "Change Records", link: "changeRecords", icon: FaBook },
]