import React from 'react'
import HomeScreen from '../screens/HomeScreen'
import usePromise from 'react-use-promise'
import { getOrderSummary } from '../api/calls'

export default function HomeContainer() {


    const [data, loading] = usePromise(getOrderSummary, [])

    return (
        <HomeScreen orderCounts={data} loading={loading} />
    )
}