import { rootReducer } from "../reducers/rootReducer";
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createStore, applyMiddleware } from "redux";
import { setApiToken } from "../api/instance";
import crud from "../api/crud";
import { logoutAction } from "../reducers/auth/actions";


const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const configureStore = () => {
    const store = createStore(persistedReducer, applyMiddleware(thunk))

    
    const checkActive = async (id) => {
        console.log(id)
        const user = await crud.getOne('user', id)
        console.log(user.active)
        if (user.active === false) {
            store.dispatch(logoutAction())
        }
    }

    const persistor = persistStore(store, null, data => {
        console.log("Persist")
        const { auth: { token } } = store.getState()
        setApiToken(token)
        const userId = store.getState().auth.user.id
        checkActive(userId)
    })
    return { store, persistor }
}
