import React, { useState } from "react";
import {
  Button,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  RadioGroup,
  Radio,
  FormErrorMessage,
  ButtonGroup
} from "@chakra-ui/core";
import Table from "../components/Table.js";
import { useField, useForm } from "react-final-form-hooks";
import { isRequiredValidator, isEmailValidator } from "../lib/validators.js";
import CrudPage from "../components/CrudPage.js";

export default function UserScreen({
  onCreate,
  onDelete,
  onEdit,
  onRefresh,
  dataSource,
  customActions
}) {
  return (
    <CrudPage
      onCreate={onCreate}
      onDelete={onDelete}
      onEdit={onEdit}
      onRefresh={onRefresh}
      renderForm={props => <UserForm {...props} />}
      customActions={customActions}
      renderTable={props => (
        <UserScreenTable {...props} dataSource={dataSource} />
      )}
      title="Users"
    />
  );
}

function UserScreenTable({ dataSource, onSelect }) {
  const columnDefs = [
    {
      headerName: "ID",
      field: "id",
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: "First Name",
      field: "firstName",
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: "Last Name",
      field: "lastName",
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agTextColumnFilter",
      sortable: true
    },
    {
      headerName: "Roles",
      field: "roles",
      filter: "agTextColumnFilter",
      sortable: true
    }
  ];
  const rowStyles = ({ data ={user:{}} }) => (data.active ? {} : { color: "grey" });
  return (
    <Table
      dataSource={dataSource}
      columnDefs={columnDefs}
      onChangeSelction={onSelect}
      rowStyles={rowStyles}
    />
  );
}

const UserForm = ({ onSubmit, onCancel, data = {}, isEdit = false }) => {
  const [hidePasswordEntry, setHidePasswordEntry] = useState(isEdit);
  const togglePasswordEntry = () => {
    setHidePasswordEntry(v => !v);
  };

  // On submit hide password entry when update if password field is disabled
  const onSubmitProcess = data => {
    if (hidePasswordEntry) {
      return onSubmit({ ...data, password: undefined });
    }
    return onSubmit(data);
  };

  const { form, handleSubmit, pristine, submitting } = useForm({
    onSubmit: onSubmitProcess,
    initialValues: {
      ...data,
      password: "",
      roles: data && data.roles ? data.roles[0] : ""
    }
  });
  const email = useField("email", form, isEmailValidator);
  const firstName = useField("firstName", form, isRequiredValidator);
  const lastName = useField("lastName", form, isRequiredValidator);
  // const contactNumber = useField('contactNumber', form, isRequiredValidator)
  const password = useField(
    "password",
    form,
    hidePasswordEntry ? undefined : isRequiredValidator
  );
  const roles = useField("roles", form, isRequiredValidator);

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing="5">
        <FormControl
          isRequired
          isInvalid={firstName.meta.touched && firstName.meta.error}
        >
          <FormLabel htmlFor="Name">First Name</FormLabel>
          <Input
            id="name"
            {...firstName.input}
            placeholder="Please enter name"
          />
          <FormErrorMessage>{firstName.meta.error}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={lastName.meta.touched && lastName.meta.error}
        >
          <FormLabel>Last Name</FormLabel>
          <Input {...lastName.input} placeholder="Please enter last name " />
          <FormErrorMessage>{lastName.meta.error}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={email.meta.error}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            {...email.input}
            placeholder="Please enter user name"
          />
          {email.meta.touched && email.meta.error && (
            <FormErrorMessage>{email.meta.error}</FormErrorMessage>
          )}

        </FormControl>

        <FormControl
          isRequired
          isInvalid={roles.meta.touched && roles.meta.error}
        >
          <FormLabel as="legend" htmlFor="UserRole">
            User role
          </FormLabel>
          {roles.input.value !== "dealer" ? (
            <RadioGroup defaultValue="Dealer" {...roles.input}>
              <Radio value="admin">Admin</Radio>
              <Radio value="viewer">Viewer</Radio>
            </RadioGroup>
          ) : (
            <FormLabel>Dealer</FormLabel>
          )}
          <FormErrorMessage>{roles.meta.error}</FormErrorMessage>
        </FormControl>

        {/* <Box>
        <FormLabel htmlFor="Contact No.">Contact No</FormLabel>
        <Input
          id="contactno"
          {...contactNumber.input}
          placeholder="Please enter contact number"
        />
      </Box> */}

        {!hidePasswordEntry ? (
          <Box>
            <FormControl
              isRequired
              isInvalid={password.meta.touched && password.meta.error}
            >
              <FormLabel htmlFor="password">Password</FormLabel>
              <InputGroup>
                <Input
                  type="password"
                  id="password"
                  {...password.input}
                  placeholder="Please enter password"
                  rounded="0"
                />
                <FormErrorMessage>{password.meta.error}</FormErrorMessage>
              </InputGroup>
            </FormControl>
          </Box>
        ) : (
          <></>
        )}
        {isEdit ? (
          <Button onClick={togglePasswordEntry}>
            {" "}
            {hidePasswordEntry ? "Change Password" : "Don't change password"}
          </Button>
        ) : (
          <></>
        )}

        <ButtonGroup>
          <Button variant="outline" mr={3} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit}
            isDisabled={submitting || pristine}
            variantColor="blue"
          >
            {isEdit ? "Update" : "Create"}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
};
