import React, { useState, useEffect } from 'react'
import PhotoSelect from '../components/PhotoSelect'
import { uploadFile } from '../api/calls'
import { getCompleteUrl } from '../lib/url'

export default function PhotoUpload({ onChange, value=undefined }) {
    const [imageUrl, setImageUrl] = useState(value)

    useEffect(() => setImageUrl(value), [value])
    const handleUpload = file => {
        uploadFile(file).then(v => {
            setImageUrl(v)
            console.log(v)
            onChange(v)
            return v
        })
    }

    return (
        <PhotoSelect onChange={handleUpload} imageUrl={getCompleteUrl(imageUrl)}>
        </PhotoSelect>
    )
}
