import { LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT, LOGIN } from "./types";
import { api, setApiToken } from "../../api/instance";

const loginStartAction = () => {
    return { type: LOGIN }
}

const loginSuccessAction = (user, token) => {
    return { type: LOGIN_SUCCESS, payload: { user, token } }
}

const loginErrorAction = () => {
    return { type: LOGIN_ERROR }
}

export const logoutAction = () => {
    return { type: LOGOUT }
}


export const loginAction = (email, password) => {

    return (dispatch) => {
        dispatch(loginStartAction())
        return api.post("api/auth/login", { email, password }).then(resp => {
            const { user, accessToken } = resp.data
            dispatch(loginSuccessAction(user, accessToken))
            setApiToken(accessToken)
            console.log(resp)
            console.log(resp.data)
            console.log(accessToken)
        }).catch(e => {
            dispatch(loginErrorAction(e.message))
        })
    }

    // return loginStartAction()
}