import React, { useState } from "react";
import { Heading, Stack, Button, ButtonGroup } from "@chakra-ui/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Table from "../components/Table.js";
import CrudPage from "../components/CrudPage.js";
import Parameter from "../components/Parameter.js";
import { formatDateTime } from "../lib/datetime.js";
import Card from "../components/Card.js";

export default function ServiceRequestScreen({
  dataSource,
  customActions,
  onToggleArchived,
  showArchived
}) {
  const [, setSelectedItem] = useState(null);
  const handleSelection = items => {
    if (items.length === 1) {
      setSelectedItem(items[0]);
    }
  };
  return (
    <CrudPage
      renderView={props => (
        <ServiceRequestView {...props} dataSource={dataSource} />
      )}
      // renderForm={props => <ServiceRequestForm {...props} brandResolver={brandResolver} />}
      renderTable={props => (
        <ServiceRequestTable {...props} dataSource={dataSource} />
      )}
      title={showArchived ? "Archived Service Requests" : "Service Requests"}
      onSelectionChange={handleSelection}
      customActions={customActions}
      renderActions={() => (
        <ActionButtons
          onToggleArchived={onToggleArchived}
          showArchived={showArchived}
        />
      )}
    ></CrudPage>
  );
}

const ActionButtons = ({ onToggleArchived, showArchived }) => {
  return (
    <ButtonGroup>
      <Button onClick={onToggleArchived}>
        {showArchived
          ? "Show active service requests"
          : "Show archived service requests"}
      </Button>
    </ButtonGroup>
  );
};

function ServiceRequestTable({ dataSource, onSelect }) {
  const ServiceRequestTableOptions = {
    columnDefs: [
      {
        headerName: "ID",
        field: "id",
        sort: "desc",
        sortable: true,
        filter: true
      },
      {
        headerName: "Dealer",
        field: "dealer.name",
        sortable: true,
        filter: true
      },
      {
        headerName: "Service",
        field: "service.name",
        sortable: true,
        filter: true
      },
      {
        headerName: "Created Date",
        field: "createdDate",
        sortable: true,
        cellRenderer: ({ value }) => {
          return formatDateTime(value);
        }
      }
    ]
  };
  const rowStyles = ({ data ={user:{}} }) => (data.outOfStock ? { color: "gray" } : {});
  return (
    <Table
      columnDefs={ServiceRequestTableOptions.columnDefs}
      dataSource={dataSource}
      rowStyles={rowStyles}
      onChangeSelction={onSelect}
    ></Table>
  );
}
// export default App;

const ServiceRequestView = ({ data }) => {
  const columnDefs = [
    {
      headerName: "Attributes",
      field: "param",
      resizable: true
    },
    {
      headerName: "Value",
      field: "value",
      resizable: true
    }
  ];

  const rows = Object.keys(data.values || {}).map(k => ({
    param: k,
    value: data.values[k]
  }));

  return (
    <div style={{ width: "100%" }}>
      <Card>
        <Heading size="md">Service Request Details</Heading>
        {/* dkla */}
        <Stack spacing="5" padding="2">
          <Parameter title="ID" value={data.id} />
          <Parameter title="Service" value={data.service.name} />
          <Parameter title="Dealer" value={data.dealer.name} />
          <Parameter title="Contact No" value={data.dealer.contactNo} />
          <Parameter
            title="Created Date"
            value={formatDateTime(data.createdDate)}
          />
        </Stack>
        {/* <StatLabel>Dealer</StatLabel>
      <StatNumber>{data.dealer.name}</StatNumber> */}
        <Table height={300} columnDefs={columnDefs} rowData={rows} />
      </Card>

      {/* {JSON.stringify(data)} */}
    </div>
  );
};
