export const successToast = (title, description = "") => ({
    title,
    description,
    status: "success",
    duration: 9000,
    isClosable: true,
})

export const errorToast = (title, description = "") => ({
    title,
    description,
    status: "error",
    duration: 9000,
    isClosable: true,
})
