import { API_START, API_SUCCESS, API_ERROR } from "./types";

const initState = {}

export const apiReducer = (state = initState, action) => {
    if (action && action.payload) {
        const { key } = action.payload
        return { ...state, [key]: singleCall(state[key], action) }
    }
    return state
}


const initSingleCall = { loading: false, data: null, success: false, error: "" }
const singleCall = (state = initSingleCall, action) => {
    if (action.type === API_START) {
        return { ...state, loading: true }
    }
    if (action.type === API_SUCCESS) {
        const { data } = action.payload
        return { ...state, loading: false, data, success: true }
    }
    if (action.type === API_ERROR) {
        const { error } = action.payload
        return { ...state, loading: false, success: false, error }
    }

}