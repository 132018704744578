import React from 'react'
import crud from '../api/crud'
import ProductScreen from '../screens/ProductsScreen'
import { getDataSource } from '../api/tableFilter'
import { useAccess } from '../reducers/api/hooks';

export default function ProductContainer() {
    const dataSource = getDataSource("product");
    const handleCreate = (data) => {
        return crud.create("product", data)
    }

    const handleDelete = (items) => {
        const promises = items.map(item => crud.deleteEntry("product", item.id))
        return Promise.all(promises)
    }

    const handleEdit = (data) => {
        return crud.update("product", data)
    }

    const searchBrands = (txt) => {
        const mapItems = item => ({ value: item.id, label: item.name })
        return crud.getAll("brand", txt ? `?filter=name||cont||${txt}` : "").then(data => data).then(items => items.map(mapItems))
    }

    const isAdmin = useAccess("admin")

    return (
        <ProductScreen
            dataSource={dataSource}
            onCreate={isAdmin ? handleCreate : undefined}
            onDelete={isAdmin ? handleDelete : undefined}
            onEdit={isAdmin ? handleEdit : undefined}
            brandResolver={searchBrands}
        />
    )
}
