import React from 'react'
import { getDataSource } from '../api/tableFilter'
import crud from '../api/crud'
import BrandScreen from '../screens/BrandScreen'
import { useAccess } from '../reducers/api/hooks'

export default function BrandContainer() {
    const dataSource = getDataSource("brand")

    const handleCreate = (data) => {
        return crud.create("brand", data)
    }

    const handleDelete = (items) => {
        const promises = items.map(item => crud.deleteEntry("brand", item.id))
        return Promise.all(promises)
    }

    const handleEdit = (data) => {
        return crud.update("brand", data)
    }

    const isAdmin = useAccess("admin")

    return (
        <BrandScreen
            onCreate={isAdmin ? handleCreate : undefined}
            onEdit={isAdmin ? handleEdit : undefined}
            onDelete={isAdmin ? handleDelete : undefined}
            dataSource={dataSource} />
    )
}
