import React from 'react'
import { useForm, useField } from 'react-final-form-hooks'
import { ButtonGroup, Stack, Box, FormLabel, Input, Button } from '@chakra-ui/core'
import { isRequiredValidator } from '../lib/validators'
import CrudPage from '../components/CrudPage'
import PhotoUpload from '../containers/PhotoUpload'
import Table from '../components/Table'

export default function BrandScreen({ onEdit, onCreate, dataSource, onDelete }) {
    return (
        <CrudPage
            title="Brands"
            onCreate={onCreate}
            onEdit={onEdit}
            onDelete={onDelete}
            renderForm={props => <BrandForm {...props} />}
            renderTable={props => <BrandTable {...props} dataSource={dataSource} />}
        />
    )
}


const BrandForm = ({ onSubmit, onCancel, data = { outOfStock: false }, isEdit = false }) => {

    const { form, handleSubmit, pristine, submitting } = useForm({ onSubmit, initialValues: data })


    const name = useField('name', form, isRequiredValidator)
    const logoUrl = useField('logoUrl', form, isRequiredValidator)
    const priority = useField('priority', form, isRequiredValidator)


    return <>
        <Stack spacing="24px">
            <Box>
                <FormLabel htmlFor="brandName">Name</FormLabel>
                <Input
                    {...name.input}
                    id="brandName"
                    placeholder="Please enter brand name"
                />
            </Box>
            <Box>
                <FormLabel htmlFor="logo">Logo</FormLabel>
                <PhotoUpload
                    {...logoUrl.input}
                    id="logo"
                />
            </Box>
            <Box>
                <FormLabel htmlFor="brandOrder">Priority</FormLabel>
                <Input
                    {...priority.input}
                    id="brandOrder"
                    placeholder="Please enter brand priority"
                />
            </Box>
           
            <ButtonGroup mt="2">
                <Button variant="outline" mr={3} onClick={onCancel}>Cancel</Button>
                <Button variantColor="blue" onClick={handleSubmit} isDisabled={pristine || submitting}>Save</Button>
            </ButtonGroup>

        </Stack>
    </>
}


const BrandTable = ({ dataSource, onSelect }) => {
    const columnDefs = [
        {
            headerName: "ID",
            field: "id",
            sortable: true,
            filter: true
        },
        {
            headerName: "Logo",
            field: "logoUrl",
            cellRenderer: "image",
        },
        {
            headerName: "Name",
            field: "name",
            sortable: true,
            filter: true
        },
        {
            headerName: "Priority",
            field: "priority",
            sortable: true,
            filter: true
        }

    ]

    return <Table
        dataSource={dataSource}
        columnDefs={columnDefs}
        onChangeSelction={onSelect}
        rowHeight={45}
    ></Table>
}