export const isRequiredValidator = v => (!!v ? undefined : "Value is required");
export const isPhoneNumberValidator = v => {
  if (v >= 100000000 && v < 1000000000) return undefined;
  else return "Enter valid contact number";
};
export const isEmailValidator = v => {
  // 
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) === true) // eslint-disable-line no-useless-escape
    return undefined;
  else return "Enter valid email address!";
};
export const isPriceValidator = v => {
  if (isNaN(v)) return "Enter valid price";
  else return undefined;
};
export const isAmountValidator = v => {
  if (isNaN(v)) return "Enter valid quantity";
  else return undefined;
};
