import React from "react";
import {
  Heading,
  Box,
  Button,
  FormLabel,
  Input,
  Stack,
  ButtonGroup,
  Badge,
  Text
} from "@chakra-ui/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Table from "../components/Table.js";
import { useField, useForm } from "react-final-form-hooks";
import { isRequiredValidator } from "../lib/validators.js";
import CrudPage from "../components/CrudPage.js";
import Parameter from '../components/Parameter.js';
import ServiceAttributeSelector from "../components/ServiceAttributeSelector.js";
import Card from "../components/Card.js";

export default function ServicesScreen({
  dataSource,
  onCreate,
  onDelete,
  onEdit,
  onRefresh,
  brandResolver
}) {
  return (
    <CrudPage
      onCreate={onCreate}
      onEdit={onEdit}
      onDelete={onDelete}
      renderForm={props => (
        <ServiceForm {...props} brandResolver={brandResolver} />
      )}
      renderTable={props => (
        <ServiceScreenTable {...props} dataSource={dataSource} />
      )}
      renderView={props => <ServiceView {...props} dataSource={dataSource} />}
      title="Services"
    ></CrudPage>
  );
}
function ServiceScreenTable({ dataSource, onSelect }) {
  const columnDefs = [
    {
      headerName: "ServiceId",
      field: "id",
      sortable: true,
      filter: true
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      filter: true
    },
    {
      headerName: "Description",
      field: "description",
      sortable: true,
      filter: true
    }
  ];

  const rowStyles = ({ data ={user:{}} }) => (data.outOfStock ? { color: "gray" } : {});
  return (
    <Table
      dataSource={dataSource}
      columnDefs={columnDefs}
      onChangeSelction={onSelect}
      rowStyles={rowStyles}
      rowHeight={45}
    ></Table>
  );
}
// export default App;

function ServiceForm({
  onSubmit,
  onCancel,
  data: initialValues,
  isEdit = false,
  brandResolver
}) {
  const processBeforeSubmit = data => {
    return onSubmit({ ...data });
  };

  const { form, handleSubmit, pristine, submitting } = useForm({
    onSubmit: processBeforeSubmit,
    initialValues
  });

  const name = useField("name", form, isRequiredValidator);
  const description = useField("description", form, isRequiredValidator);
  const attributes = useField("attributes", form);
  console.log("hello")

  return (
    <>
      <Stack spacing="24px">
        <Box>
          <FormLabel htmlFor="serviceName">Name</FormLabel>
          <Input
            {...name.input}
            id="serviceName"
            placeholder="Please enter service name"
          />
        </Box>
        {/* <Box>
          <FormLabel htmlFor="imageUrl">Image</FormLabel>
          <PhotoUpload {...imageUrl.input} />
        </Box> */}
        <Box>
          <FormLabel htmlFor="Description">Description</FormLabel>
          <Input
            {...description.input}
            id="description"
            as="textarea"
            placeholder="Please enter description"
          />
        </Box>
        <Box>
          <FormLabel htmlFor="Attribute">Attributes</FormLabel>
          <ServiceAttributeSelector {...attributes.input} />
        </Box>

      </Stack>
      <ButtonGroup mt="2">
        <Button variant="outline" mr={3} onClick={onCancel}>
          Cancel
        </Button>
        <Button variantColor="blue" isDisabled={pristine || submitting} onClick={handleSubmit}>
          Save
        </Button>
      </ButtonGroup>
    </>

  );

}

const ServiceView = ({ data }) => {
  // const columnDefs = [
  //   {
  //     headerName: "Product Id",
  //     field: "product.id",
  //     resizable: true
  //   },
  //   {
  //     headerName: "Name",
  //     field: "product.name",
  //     resizable: true
  //   },
  //   {
  //     headerName: "Price",
  //     field: "product.price",
  //     resizable: true
  //   }
  // ]
  return <div style={{ width: '100%' }}>
    <Card>

      <Heading size="md">Service Details</Heading>
      {/* dkla */}
      <Stack spacing="5" padding="2">
        <Parameter title="ID" value={data.id} />
        <Parameter title="Name" value={data.name} />

        <Parameter title="Description" value={data.description} />
        <Box margin="2" alignContent="flex-start">
          <Text textAlign="left">Attributes</Text>
          <Stack isInline>
            {data.attributes.map((item, i) => <Badge >{item}</Badge>)}
          </Stack>
        </Box>

      </Stack>
      {/* <StatLabel>Dealer</StatLabel>
      <StatNumber>{data.dealer.name}</StatNumber> */}
      {/* <Table height={300} columnDefs={columnDefs} rowData={data.orderProducts} /> */}
    </Card>

    {/* {JSON.stringify(data)} */}
  </div>
}


// function DrawerAddService() {
//   const [isOpen, setIsOpen] = React.useState(false);
//   const toast = useToast();
//   function CreateButtonSuccess() {
//     setIsOpen(false);
//     toast({
//       title: "Service Created",
//       description: "Service successfully created",
//       status: "success",
//       duration: 3000,
//       isClosable: true
//     });
//   }

//   return (
//     <>
//       <Button color="purple.600" onClick={() => setIsOpen(true)}>
//         Add Service
//       </Button>
//       <Drawer
//         isOpen={isOpen}
//         zIndex={6}
//         placement="right"
//         //initialFocusRef={firstField}
//         onClose={() => setIsOpen(false)}
//       >
//         <DrawerCloseButton onClick={() => setIsOpen(false)} />
//         <DrawerHeader borderBottomWidth="1px">
//           Create a new product
//         </DrawerHeader>

//         <DrawerBody>
//           <Stack spacing="24px">
//             <Box>
//               <FormLabel htmlFor="serviceName">Name</FormLabel>
//               <Input
//                 //ref={firstField}
//                 id="serviceName"
//                 placeholder="Please enter service name"
//               />
//             </Box>

//             <Box>
//               <FormLabel htmlFor="productName">product name</FormLabel>
//               <Input
//                 //ref={firstField}
//                 id="productname"
//                 placeholder="Please enter product name"
//               />
//             </Box>
//           </Stack>
//         </DrawerBody>

//         <DrawerFooter borderTopWidth="1px">
//           <Button variant="outline" mr={3} onClick={() => setIsOpen(false)}>
//             Cancel
//           </Button>
//           <Button variantColor="blue" onClick={() => CreateButtonSuccess()}>
//             Create User
//           </Button>
//         </DrawerFooter>
//       </Drawer>
//     </>
//   );
// }
