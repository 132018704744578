import React from 'react'
import { Heading, Text, Box } from '@chakra-ui/core'

export default function Parameter({ title, value }) {
    return (
        <Box margin="2" alignContent="flex-start">
            <Text textAlign="left">{title}</Text>
            <Heading textAlign="left" size="md">{value}</Heading>
        </Box>
    )
}
