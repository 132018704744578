import React from "react";
import {
  FormControl,
  FormLabel,
  Heading,
  Button,
  Box,
  Input,
  Text
} from "@chakra-ui/core";
import { useForm, useField } from 'react-final-form-hooks'


export default function LoginScreen({ onSubmit, error }) {
  const { form, handleSubmit, pristine, submitting } = useForm({
    onSubmit, // the function to call with your form values upon valid submit
    // validate // a record-level validation function to check all form values
  })
  const email = useField('email', form)
  const password = useField('password', form)
  return (
    <div align="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}  >
      <Box rounded="lg" bg="brand.700" w="40%" p={4} alignItems="center">
        <Heading fontSize="50px" pt={4}>
          Login Screen
        </Heading>
        <form onSubmit={handleSubmit}>
          <FormControl mb={8} pt={6} borderColor="gray.50">
            <FormLabel htmlFor="username">Username</FormLabel>
            <Input {...email.input} type="text" id="username" placeholder="Username" />
          </FormControl>
          <FormControl pt={0} pb={3}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input {...password.input} type="password" id="password" placeholder="Password" />
          </FormControl>
          {error ? <Text color="red.200">Login Error</Text> : null}
          <Button isDisabled={pristine || submitting} type="submit"> Login </Button>
        </form>
      </Box>
    </div>

  );


}
