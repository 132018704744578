import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import LoginScreen from '../screens/LoginScreen';
import { loginAction } from '../reducers/auth/actions';

export default function LoginContainer() {
    const dispatch = useDispatch()
    const error = useSelector(s => s.auth.error)
    const handleSubmit = ({ email, password }) => {
        dispatch(loginAction(email, password))
    }
    return (
        <LoginScreen onSubmit={handleSubmit} error={error} />
    )
}
