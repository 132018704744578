import React from "react";
import crud from "../api/crud";
import ServicesScreen from "../screens/ServicesScreen";
import { getDataSource } from "../api/tableFilter";
import { useAccess } from "../reducers/api/hooks";

export default function ServicesContainer() {
  //   const { data, success, loading, refresh } = useApi("services", "services");
  const dataSource = getDataSource("service");

  const handleCreate = data => {
    return crud.create("service", data);
  };

  const handleDelete = items => {
    const promises = items.map(item => crud.deleteEntry("service", item.id));
    return Promise.all(promises);
  };

  const handleEdit = data => {
    return crud.update("service", data);
  };

  const searchBrands = txt => {
    const mapItems = item => ({ value: item.id, label: item.name });
    return crud
      .getAll("brand", txt ? `?filter=name||cont||${txt}` : "")
      .then(data => data)
      .then(items => items.map(mapItems));
  };


  const isAdmin = useAccess("admin")

  return (
    <ServicesScreen
      dataSource={dataSource}
      onCreate={isAdmin ? handleCreate : undefined}
      onDelete={isAdmin ? handleDelete : undefined}
      onEdit={isAdmin ? handleEdit : undefined}
      //   onRefresh={refresh}
      brandResolver={searchBrands}
    />
  );
}
