import React from 'react';
import AsyncSelect from 'react-select/async';

// const filterColors = (inputValue) => {
//     return colourOptions.filter(i =>
//         i.label.toLowerCase().includes(inputValue.toLowerCase())
//     );
// };


export default function EntitySelect({ onChange, value, isMulti, resolver }) {
    const promiseOptions = async inputValue => {
        const resp = await resolver(inputValue).catch(e => {
            console.log(e)
            return []
        })
        console.log(resp)
        return resp
    }

    const handleChange = (data) => {
        // console.log("Data", data)
        onChange(data)
    }

    return (
        <>
            <AsyncSelect value={value} isMulti={isMulti} onChange={handleChange} cacheOptions defaultOptions loadOptions={promiseOptions} />
        </>
    );
}