import React from 'react'
import {
    Drawer,
    DrawerHeader,
    DrawerBody,
    Button,
    Flex,
    useColorMode,
    Stack,
    Box,
    Heading,
    DrawerOverlay,
    DrawerContent
} from "@chakra-ui/core";
import { Link } from "react-router-dom"
import { DASHBOARD_NAME } from '../config/config';

export default function CustomDrawer({ links, name, onLogout, loggedIn }) {

    const [isOpen, setIsOpen] = React.useState(false);

    const { colorMode, toggleColorMode } = useColorMode();

    return (
        <>
            <Flex justify="space-between" alignItems="center" p="1" width="100%" bg="blackAlpha.400" >
                <Button aria-label="Search database" rightIcon="arrow-right" onClick={() => setIsOpen(true)} >Menu </Button>
                <Heading size="md" color="brand.900">{DASHBOARD_NAME}</Heading>
                <Button onClick={toggleColorMode}>
                    Toggle {colorMode === "light" ? "Dark" : "Light"}
                </Button>
            </Flex>
            <Drawer
                isOpen={isOpen}
                size="xs"
                zIndex={6}
                placement="left"
                onClose={() => setIsOpen(false)}
            >
            <DrawerOverlay/>
            <DrawerContent>
                <DrawerHeader borderBottomWidth="1px">
                    Menu
                    <br />
                    {loggedIn && <Button onClick={onLogout} >Logout ({name})</Button>}
                </DrawerHeader>
                <DrawerBody>
                    <Stack spacing="1">
                        {links.map(l => <Box key={l.name}>
                            <Link to={l.link}>
                                <Button   alignItems="left" variantColor="blue" width="90%" variant="solid" vaC leftIcon={l.icon}> {l.name}</Button>
                            </Link>
                        </Box>)}
                    </Stack>
                </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}
