import { useSelector, useDispatch } from "react-redux"
import { apiCallAction } from "./actions";
import { useEffect, useCallback } from "react";
export const useApi = (key, subUrl, { method = "GET", data: submitData = {} } = {}, autoCall = true) => {

    const dispatch = useDispatch()
    const { data, success, loading } = useSelector(s => s.api[key] || {})
    const refresh = useCallback(() => {
        dispatch(apiCallAction(key, subUrl, { method }))
    }, [dispatch, method, key, subUrl])

    useEffect(() => {
        autoCall && setImmediate(refresh)
    }, [autoCall, refresh])
    return { data, success, loading, refresh }
}

export const useAccess = (role) => {
    const user = useSelector(s => s.auth.user)

    if (user && user.roles) {
        return user.roles.some(v => v === role)
    }
    return false
}