import { api } from "./instance";

export const createUser = (data) => {
    return api.post("api/auth/register", data).then(resp => resp.data)
}

export const uploadFile = file => {
    const formData = new FormData()
    formData.append("file", file)
    return api.post("files/upload", formData).then(resp => {
        console.log(resp)
        return resp.data
    })
}

export const getOrderSummary = (data) => {
    return api.get("order/summary").then(resp => resp.data)
}

export const send = (data) => {
    return api.post("notification/send", data)
    .then(resp => resp.data)
}

export const sendMany = (data) => {
    return api.post("notification/sendMany", data)
    .then(resp => resp.data)
}