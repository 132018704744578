import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // LineChart,
  // Line,
  BarChart,
  Legend,
  Bar,
  Brush,
  AreaChart,
  Area
} from "recharts";
import { Flex, Heading } from "@chakra-ui/core";
import Card from "../components/Card.js";
import { theme } from "../styles/theme.js";

export default function HomeScreen({ orderCounts = [] }) {
  orderCounts = orderCounts
    ? orderCounts.map(oc => ({ ...oc, date: oc.date.split("T")[0] }))
    : [];

    let startIndex;
    if(orderCounts.length < 7)
    startIndex = 0
    else startIndex = orderCounts - 7;



  return (
    <div>
      <Card>
        <Flex align="center" justifyContent="space-between" w="100%" p={2}>
          <Heading color="brand.900" fontSize="40px" pt={2} pb={3}>
            Dashboard
          </Heading>
        </Flex>

        {/* <Code>{JSON.stringify(orderCounts)}</Code>
        <Code>{JSON.stringify(loading)}</Code> */}
        <Flex direction="column" alignItems="center">
          <Heading>Orders</Heading>
          <BarChart width={600} height={400} data={orderCounts}>
            <CartesianGrid strokeDasharray="5 3" />
            <XAxis dataKey="date" />
            <YAxis dataKey="count" type="number" />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="count"
              label="Orders"
              fill={theme.colors.brand[700]}
            />
            <Brush
              dataKey="date"
              height={20}
              startIndex={startIndex}
              endIndex={orderCounts.length - 1}
            >
            <AreaChart>
            <Area dataKey="count" fill="#8884d8" stackId="a" />
          </AreaChart></Brush>
          </BarChart>
        </Flex>
      </Card>

      {/* <h2 align="left">Orders and Service Requests</h2>
      <BarChart
        width={600}
        height={400}
        data={data1}
        margin={{ top: 50, right: 100, left: 20, bottom: 70 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={<TiltedAxisTick />} interval={0} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Orders" fill="#82ca9d" />
        <Bar dataKey="ServiceRequests" fill="#8884d8" />
        <Brush
          dataKey="name"
          height={30}
          y={340}
          stroke="#000000"
          startIndex={data1.length - 6}
          endIndex={data1.length - 1}
        >
          <AreaChart>
            <Area dataKey="ServiceRequests" fill="#8884d8" stackId="a" />
            <Area dataKey="Orders" fill="#82ca9d" stackId="a" />

          </AreaChart>

        </Brush>
      </BarChart> */}
    </div>
  );
}

