import { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from "./types";

const initState = {
    user: null,
    token: null,
    loggedIn: false,
    loading: false,
    error: false
}

export const authReducer = (state = initState, action) => {
    if (action.type === LOGIN) {
        return { ...state, loading: true, error: false }
    }
    if (action.type === LOGIN_SUCCESS) {
        const { user, token } = action.payload
        return { ...state, user, token, loading: false, error: false, loggedIn: true }
    }
    if (action.type === LOGIN_ERROR) {
        return { ...state, loading: false, error: true }
    }
    if (action.type === LOGOUT) {
        return initState
    }
    return state
}