import { theme as defaultTheme } from "@chakra-ui/core";

export const theme = {
    ...defaultTheme,

    colors: {
        ...defaultTheme.colors,
        brand: {
            900: "#6b46c1",
            800: "#153e75",
            700: "#6c4cc18a",
        },
    },
};