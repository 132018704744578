import React from 'react'
import { getDataSource } from '../api/tableFilter'
import ChangeRecordScreen from '../screens/ChangeRecordsScreen'

export default function ChangeRecordsContainer() {
    // const { data, success, loading, refresh } = useApi("orders", "order")
    const dataSource = getDataSource("change-record")
    return (
        <ChangeRecordScreen dataSource={dataSource} />
    )
}
