import React from 'react';
import { Provider } from "react-redux"
import './App.css';
import { theme } from './styles/theme';
import { ThemeProvider, CSSReset, ColorModeProvider } from '@chakra-ui/core';
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore } from './boot/configureStore';
import Navigator from './containers/Navigator';


const { store, persistor } = configureStore()
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <ColorModeProvider>
              <CSSReset />
              <Navigator />
            </ColorModeProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
