import React, { useRef, useEffect } from 'react'
import { AgGridReact } from "ag-grid-react";
import useComponentSize from '@rehooks/component-size'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import { useColorMode, Image } from '@chakra-ui/core';
import { getCompleteUrl } from '../lib/url';


export default function Table({
    columnDefs,
    rowData,
    onChangeSelction,
    rowHeight = 30,
    rowStyles = undefined,
    dataSource = undefined,
    paginationPageSize=20,
    height = 500,
}) {
    const handleSelect = event => {
        const selectedNodes = event.api.getSelectedNodes()
        const selectedData = selectedNodes.map(node => node.data)

        onChangeSelction && onChangeSelction(selectedData)
        console.log(selectedData)
    }

    const { colorMode } = useColorMode();

    const compRef = useRef()
    const { width } = useComponentSize(compRef)


    const tableRef = useRef()
    useEffect(() => {
        const { api } = tableRef.current || {}
        api && api.sizeColumnsToFit(width)
        api.resetRowHeights();
    }, [width])

    return (
        <div
            ref={compRef}
            className={colorMode === "light" ? "ag-theme-balham" : "ag-theme-balham-dark"}
            style={{
                height: paginationPageSize * rowHeight + 100,
                width: "100%",
                textAlign: 'left'
            }}
        >
            {/* {JSON.stringify([height, width])} */}
            <AgGridReact
                ref={tableRef}
                floatingFilter={true}
                gridOptions={{ getRowStyle: rowStyles, datasource: dataSource }}
                rowHeight={rowHeight}
                rowSelection="multiple"
                defaultColDef={{
                    sortable: true,
                    resizable: true
                }}
                rowModelType={dataSource ? "infinite" : undefined}
                datasource={dataSource}
                serverSideDatasource={dataSource}
                pagination={true}
                paginationPageSize={paginationPageSize}
                // autoHeight={true}
                onRowDoubleClicked={event => console.log("Double click", event)}
                // rowMultiSelectWithClick={true}
                onSelectionChanged={handleSelect}
                columnDefs={columnDefs}
                rowData={rowData || []}
                frameworkComponents={frameworkComponents}
            ></AgGridReact>
        </div>
    )
}


const TableImage = ({ value }) => <Image src={getCompleteUrl(value)} objectFit="contain" height="40px" />
const frameworkComponents = {
    "image": TableImage
}
