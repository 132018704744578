import React from 'react'
import {
    Drawer,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    DrawerCloseButton,
    DrawerOverlay,
    DrawerContent
} from "@chakra-ui/core"

export default function SideDrawer({ isOpen, onClose, children, title }) {
    return (
        <Drawer
            isFullHeight
            isOpen={isOpen}
            zIndex={6}
            placement="right"
            onClose={onClose}
            size="lg">
            <DrawerOverlay/>
            <DrawerContent>
            <DrawerCloseButton onClick={onClose} />
            <DrawerHeader borderBottomWidth="1px">{title}</DrawerHeader>

            <DrawerBody isFullHeight style={{ overflowX: 'scroll' }} >
                {children}
                {/* <UserForm onSubmit={handleCreate} onCancel={() => setIsOpen(false)} /> */}
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px">
            </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}
