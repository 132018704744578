import React from 'react'
import { Image, Box } from "@chakra-ui/core"

export default function PhotoSelect({ imageUrl, onChange }) {
    const handleChange = (e) => {
        onChange(e.target.files[0])
    }
    return (
        <Box>
            <Image size="100px" objectFit="cover" src={imageUrl} />
            <input type="file" onChange={handleChange} />
        </Box>
    )
}
