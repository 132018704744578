import React from "react";
import {
  Button,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody
} from "@chakra-ui/core";

export default function ConfirmActionButton({
  style,
  buttonColor,
  innerText,
  isDisabled,
  yesClicked
}) {
  const [isOpen, setIsOpen] = React.useState();
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();

  if (isOpen) {
  }
  const handleYes = () => {
    setIsOpen(false)
    yesClicked && yesClicked()
  }

  return (
    <Button
      style={style}
      variantColor={buttonColor}
      pt="md"
      isDisabled={isDisabled}
      onClick={() => setIsOpen(true)}
    >
      {innerText}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >

        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete entry
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button variantColor="red" onClick={handleYes} ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Button>
  );
}
