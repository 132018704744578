import React from 'react'
import { useForm, useField } from 'react-final-form-hooks'
import { ButtonGroup, Stack, Box, FormLabel, Input, Button, FormControl, FormErrorMessage } from '@chakra-ui/core'
import { isRequiredValidator } from '../lib/validators'
import CrudPage from '../components/CrudPage'
import PhotoUpload from '../containers/PhotoUpload'
import Table from '../components/Table'

export default function PromotionsScreen({ onEdit, onCreate, dataSource, onDelete }) {
  return (
    <CrudPage
      title="Promotions"
      onCreate={onCreate}
      onEdit={onEdit}
      onDelete={onDelete}
      renderForm={props => <PromotionForm {...props} />}
      renderTable={props => <PromotionTable {...props} dataSource={dataSource} />}
    />
  )
}


const PromotionForm = ({ onSubmit, onCancel, data = {}, isEdit = false }) => {

  const { form, handleSubmit, pristine, submitting } = useForm({ onSubmit, initialValues: data })


  const title = useField('title', form, isRequiredValidator)
  const description = useField('description', form, isRequiredValidator)
  const imageUrl = useField('imageUrl', form, isRequiredValidator)
  const timePeriod = useField('timePeriod', form, isRequiredValidator)
  const termsAndConditions = useField('termsAndConditions', form, isRequiredValidator)
  const maxOrderQuantity = useField('product.maxOrderQuantity', form, isRequiredValidator)


  return <>
    <Stack spacing="24px">
      <Box>
        <FormLabel htmlFor="brandName">Name</FormLabel>
        <Input
          {...title.input}
          id="brandName"
        />
      </Box>
      <Box>
        <FormLabel htmlFor="brandName">Description</FormLabel>
        <Input
          {...description.input}
          as="textarea"
          id="brandName"
        />
      </Box>
      <Box>
        <FormLabel htmlFor="brandName">Time Period</FormLabel>
        <Input
          {...timePeriod.input}
          id="brandName"
        />
      </Box>
      <Box>
        <FormLabel htmlFor="brandName">Terms and Conditions</FormLabel>
        <Input
          {...termsAndConditions.input}
          as="textarea"
          id="brandName"
        />
      </Box>
      <Box>
        <FormControl isInvalid={maxOrderQuantity.meta.error} isRequired>
          <FormLabel htmlFor="Username">Maximum Order Quantity</FormLabel>
          <Input
            {...maxOrderQuantity.input}
            id="maxOrderQty"
            placeholder="Please enter maximum order quantity"
          />
          {maxOrderQuantity.meta.touched && maxOrderQuantity.meta.error && (
            <FormErrorMessage>{maxOrderQuantity.meta.error}</FormErrorMessage>
          )}
        </FormControl>
      </Box>
      <Box>
        <FormLabel htmlFor="logo">Logo</FormLabel>
        <PhotoUpload
          {...imageUrl.input}
          id="logo"
        />
      </Box>
      <ButtonGroup mt="2">
        <Button variant="outline" mr={3} onClick={onCancel}>Cancel</Button>
        <Button variantColor="blue" onClick={handleSubmit} isDisabled={pristine || submitting}>Save</Button>
      </ButtonGroup>
    </Stack>
  </>
}


const PromotionTable = ({ dataSource, onSelect }) => {
  const columnDefs = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      filter: true
    },
    {
      headerName: "Image",
      field: "imageUrl",
      cellRenderer: "image",
    },
    {
      headerName: "Title",
      field: "title",
      sortable: true,
      filter: true
    },
    {
      headerName: "Description",
      field: "description",
      sortable: true,
      filter: true
    }]

  return <Table
    dataSource={dataSource}
    columnDefs={columnDefs}
    onChangeSelction={onSelect}
    rowHeight={45}
  ></Table>
}