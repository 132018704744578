import React, { useState } from 'react'
import {
    Button,
    useToast,
    ButtonGroup,
    Flex,
    Heading
} from '@chakra-ui/core'
import SideDrawer from '../components/SideDrawer.js';
import { successToast, errorToast } from '../components/Toasts.js';
import ConfirmActionButton from './ConfirmButton.js';
import Card from './Card.js';


function ContentPage({ title, actionButtons, children }) {
    return (
        <Card>
            <Flex align="center" justifyContent="space-between" w="100%" p={2}>
                <Heading fontSize="40px" color="brand.900" pt={2} pb={3}>{title}</Heading>
                <Flex align="flex-end">
                    {actionButtons}
                </Flex>
            </Flex>
            {children}
        </Card>
    )
}


export default function CrudPage({
    renderTable,
    renderForm,
    renderView,
    renderActions,
    customActions = [],
    onCreate,
    onEdit,
    onDelete,
    data,
    onSelectionChange,
    title,
    children
}) {
    const toast = useToast()

    // Selection
    const [selectedItems, setSelectedItems] = React.useState([])
    const [isEdit, setIsEdit] = React.useState(false)
    const isMultiOperationDisabled = selectedItems.length === 0
    const isSingleOperationDisabled = selectedItems.length !== 1

    const onSuccess = (eventName) => () => {
        toast(successToast(`${title} ${eventName}`, `Success`))
    }

    const onError = eventName => () => {
        toast(errorToast(`${title} ${eventName} Failed`, `Error`))
    }

    // Create
    const handleCreate = (data) => {
        return onCreate(data)
            .then(onSuccess("Create"))
            .then(handleCloseDrawer)
            .then(handleRefresh)
            .catch(onError("Create"))
    }

    const [isDrawerOpen, setDrawerOpen] = React.useState(false);
    const handleCloseDrawer = () => setDrawerOpen(false)
    const handleOpenCreate = () => {
        setIsEdit(false)
        setDrawerOpen(true)
    }

    // Delete
    const handleDelete = () => {
        onDelete(selectedItems).then(onSuccess("Delete"))
            .then(handleCloseDrawer)
            .then(handleRefresh)
            .catch(onError("Delete"))
    }


    // Edit 
    const handleOpenEdit = () => {
        setIsEdit(true)
        setDrawerOpen(true)
    }
    const handleEdit = (data) => {
        onEdit(data)
            .then(onSuccess("Edit"))
            .then(handleCloseDrawer)
            .then(handleRefresh)
            .catch(onError("Edit"))
    }

    const handleSelect = (items) => {
        onSelectionChange && onSelectionChange(items)
        setSelectedItems(items)
    }


    const [tableKey, setTableKey] = useState(0)
    const handleRefresh = () => {
        setTableKey(v => (v + 1) % 10)
        setSelectedItems([])
    }


    const displayView = selectedItems && selectedItems.length === 1 && renderView


    const handleCustomAction = action => {
        const resp = action.onClick && action.onClick(selectedItems)
        if (resp && resp.then) {
            resp.then(handleRefresh)
        }
    }

    return (
        <ContentPage
            title={title}
            actionButtons={
                <Flex>
                    <ButtonGroup marginRight="5">
                        {customActions.map(action => <Button
                            id="custom"
                            onClick={() => handleCustomAction(action)}
                            display={action.getDisplay ? action.getDisplay(selectedItems) : undefined}
                            isDisabled={isMultiOperationDisabled}
                            variantColor={action.variantColor}
                        >{action.title}</Button>)}

                        <Button onClick={handleRefresh}>Refresh</Button>
                        <Button style={{ display: !onCreate ? 'none' : undefined }} onClick={handleOpenCreate}>Create {title}</Button>
                        <Button style={{ display: !onEdit ? 'none' : undefined }} onClick={handleOpenEdit} variantColor="blue" isDisabled={isSingleOperationDisabled}>Edit</Button>
                        <ConfirmActionButton style={{ display: !onDelete ? 'none' : undefined }} buttonColor="red" innerText="Delete" isDisabled={isMultiOperationDisabled} yesClicked={handleDelete} />

                    </ButtonGroup>
                    {renderActions && renderActions({selectedItems})}

                </Flex>
            }>
            <Flex align="flex-start">
                <Flex align="flex-end" padding="2" w={renderView ? '60%' : '100%'}>
                    {renderTable && renderTable({ data, onSelect: handleSelect, key: tableKey })}
                </Flex>

                {/* View */}
                {displayView ? <Flex padding="2" align="flex-end" w="40%">
                    {renderView && renderView({ data: selectedItems[0] })}
                </Flex> : (renderView && <Flex align="flex-end" padding="2" w="40%" />)}
            </Flex>
            <SideDrawer isOpen={isDrawerOpen} title={isEdit ? `Edit ${title}` : `Create ${title}`} onClose={handleCloseDrawer}>
                {renderForm && renderForm({
                    isEdit, data: isEdit ? selectedItems[0] : {},
                    onCancel: handleCloseDrawer,
                    onSubmit: isEdit ? handleEdit : handleCreate
                })}


            </SideDrawer>
            {children}
        </ContentPage>
    )
}
