import React from 'react'
import {
    InputGroup,
    Input,
    InputRightElement,
    Button,
    Stack,
    Badge,
    CloseButton,
    Flex,
} from '@chakra-ui/core'

export default function ServiceAttributeSelector({ value = [], onChange }) {
    const [text, setText] = React.useState("")
    const handleClick = () => {
        onChange([...value, text])
        setText("")
    }
    const handleChange = (e) => {
        setText(e.target.value)
    }
    const handleDelete = (item) => {
        onChange(value.filter(v => v !== item))
    }
    return (
        <div>
            {/* {JSON.stringify(value)} */}
            <InputGroup size="md">
                <Input
                    pr="4.5rem"
                    type="text"
                    value={text}
                    id="attribute"
                    placeholder="Enter Attribute Name"
                    onChange={handleChange}
                />
                <InputRightElement width="4.5rem">
                    <Button
                        h="1.75rem"
                        size="sm"
                        onClick={handleClick}
                        rightIcon="add"
                    >
                        Add
                    </Button>
                </InputRightElement>
            </InputGroup>
            <Stack isInline marginTop="1">
                {value.length ? value.map(item => <Flex>
                    <Badge>{item}</Badge>
                    <CloseButton onClick={() => handleDelete(item)} />
                </Flex>) : null}
            </Stack>
        </div>
    )
}
