import React, { useState, useEffect } from "react";
import {
  Heading,
  Box,
  Button,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  ButtonGroup,
  Switch,
  FormErrorMessage,
  FormControl,
  SimpleGrid,
  Image
} from "@chakra-ui/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Table from "../components/Table.js";
import { useField, useForm } from "react-final-form-hooks";
import {
  isRequiredValidator,
  isPriceValidator,
  isAmountValidator
} from "../lib/validators.js";
import CrudPage from "../components/CrudPage.js";
import PhotoUpload from "../containers/PhotoUpload.js";
import EntitySelect from "../components/EntitySelect.js";
import Parameter from "../components/Parameter.js";
import Card from "../components/Card.js";
import { getCompleteUrl } from "../lib/url.js";

export default function ProductScreen({
  dataSource,
  onCreate,
  onDelete,
  onEdit,
  brandResolver
}) {
  const [, setSelectedItem] = useState(null);
  const handleSelection = items => {
    if (items.length === 1) {
      setSelectedItem(items[0]);
    }
  };
  return (
    <CrudPage
      onCreate={onCreate}
      onEdit={onEdit}
      onDelete={onDelete}
      renderForm={props => (
        <ProductForm {...props} brandResolver={brandResolver} />
      )}
      renderTable={props => (
        <ProductScreenTable {...props} dataSource={dataSource} />
      )}
      renderView={props => <ProductView {...props} dataSource={dataSource} />}
      title="Products"
      onSelectionChange={handleSelection}
    ></CrudPage>
  );
}
function ProductScreenTable({ dataSource, onSelect }) {
  const ProductTableOptions = {
    columnDefs: [
      {
        headerName: "ID",
        field: "id",
        sortable: true,
        filter: true
      },
      {
        headerName: "Image",
        field: "imageUrl",
        cellRenderer: "image"
      },
      {
        headerName: "Brand",
        field: "brand.name",
        sortable: true,
        filter: true
      },
      {
        headerName: "Name",
        field: "name",
        sortable: true,
        filter: true
      },
      {
        headerName: "Dealer Price",
        field: "price",
        sortable: true,
        filter: true
      },
      {
        headerName: "Out of stock",
        field: "outOfStock",
        sortable: true,
        filter: true
      }
    ]
  };

  const rowStyles = ({ data ={user:{}} }) => (data.outOfStock ? { color: "gray" } : {});
  return (
    <Table
      dataSource={dataSource}
      columnDefs={ProductTableOptions.columnDefs}
      onChangeSelction={onSelect}
      rowStyles={rowStyles}
      rowHeight={45}
    ></Table>
  );
}
// export default App;

function ProductForm({
  onSubmit,
  onCancel,
  data = { outOfStock: false },
  isEdit = false,
  brandResolver
}) {
  const processBeforeSubmit = data => {
    return onSubmit({ ...data, brand: data.brand.value });
  };

  const [initialValues, setInitialValues] = useState(data);
  useEffect(() => {
    setInitialValues({
      ...data,
      brand: data.brand
        ? { value: data.brand.id, label: data.brand.name }
        : undefined
    });
  }, [data]);

  const { form, handleSubmit, pristine, submitting } = useForm({
    onSubmit: processBeforeSubmit,
    initialValues
  });

  const name = useField("name", form, isRequiredValidator);
  const price = useField("price", form, isPriceValidator);
  const brand = useField("brand", form, isRequiredValidator);
  const imageUrl = useField("imageUrl", form, isRequiredValidator);
  const imageUrl2 = useField("imageUrl2", form);
  const imageUrl3 = useField("imageUrl3", form);
  const imageUrl4 = useField("imageUrl4", form);
  const imageUrl5 = useField("imageUrl5", form);
  const description = useField("description", form, isRequiredValidator);
  const outOfStock = useField("outOfStock", form);
  const maxOrderQuantity = useField(
    "maxOrderQuantity",
    form,
    isAmountValidator
  );

  return (
    <>
      <Stack spacing="24px">
        <FormControl isInvalid={name.meta.error} isRequired>
          <FormLabel htmlFor="productName">Name</FormLabel>
          <Input
            {...name.input}
            id="productName"
            placeholder="Please enter product name"
          />
          {name.meta.touched && name.meta.error && (
            <FormErrorMessage>{name.meta.error}</FormErrorMessage>
          )}
        </FormControl>

        <SimpleGrid columns={2} spacing={1}>
          <Box>
            <FormControl isInvalid={imageUrl.meta.error}>
              <FormLabel htmlFor="imageUrl">Image 1</FormLabel>
              <PhotoUpload {...imageUrl.input} />
              {imageUrl.meta.touched && imageUrl.meta.error && (
                <FormErrorMessage>{imageUrl.meta.error}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl isInvalid={imageUrl2.meta.error}>
              <FormLabel htmlFor="imageUrl2">Image 2</FormLabel>
              <PhotoUpload {...imageUrl2.input} />
              {imageUrl2.meta.touched && imageUrl2.meta.error && (
                <FormErrorMessage>{imageUrl2.meta.error}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl isInvalid={imageUrl3.meta.error}>
              <FormLabel htmlFor="imageUrl3">Image 3</FormLabel>
              <PhotoUpload {...imageUrl3.input} />
              {imageUrl3.meta.touched && imageUrl3.meta.error && (
                <FormErrorMessage>{imageUrl3.meta.error}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl isInvalid={imageUrl4.meta.error}>
              <FormLabel htmlFor="imageUrl4">Image 4</FormLabel>
              <PhotoUpload {...imageUrl4.input} />
              {imageUrl4.meta.touched && imageUrl4.meta.error && (
                <FormErrorMessage>{imageUrl4.meta.error}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
          <Box>
            {" "}
            <FormControl isInvalid={imageUrl5.meta.error}>
              <FormLabel htmlFor="imageUrl5">Image 5</FormLabel>
              <PhotoUpload {...imageUrl5.input} />
              {imageUrl5.meta.touched && imageUrl5.meta.error && (
                <FormErrorMessage>{imageUrl5.meta.error}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
        </SimpleGrid>

        <FormControl isInvalid={price.meta.error} isRequired>
          <FormLabel htmlFor="Price">Dealer Price</FormLabel>
          <Input {...price.input} id="price" placeholder="Please enter price" />
          {price.meta.touched && price.meta.error && (
            <FormErrorMessage>{price.meta.error}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="brand">Brand</FormLabel>
          <EntitySelect resolver={brandResolver} {...brand.input} />
        </FormControl>

        <FormControl isInvalid={maxOrderQuantity.meta.error} isRequired>
          <FormLabel htmlFor="Username">Maximum Order Quantity</FormLabel>
          <Input
            {...maxOrderQuantity.input}
            id="maxOrderQty"
            placeholder="Please enter maximum order quantity"
          />
          {maxOrderQuantity.meta.touched && maxOrderQuantity.meta.error && (
            <FormErrorMessage>{maxOrderQuantity.meta.error}</FormErrorMessage>
          )}
        </FormControl>

        <Box>
          <FormLabel htmlFor="Contact No.">Description</FormLabel>
          <Input
            {...description.input}
            id="contactno"
            as="textarea"
            placeholder="Please enter description"
          />
        </Box>

        <Box>
          <FormLabel htmlFor="Stock">Out of Stock</FormLabel>
          <InputGroup>
            <Switch
              color="red"
              isChecked={outOfStock.input.value}
              {...outOfStock.input}
            />
          </InputGroup>
        </Box>
      </Stack>
      <ButtonGroup mt="2">
        <Button variant="outline" mr={3} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variantColor="blue"
          isDisabled={pristine || submitting}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </ButtonGroup>
    </>
  );
}

const ProductView = ({ data }) => {
  // const columnDefs = [
  //   {
  //     headerName: "Product Id",
  //     field: "product.id",
  //     resizable: true
  //   },
  //   {
  //     headerName: "Name",
  //     field: "product.name",
  //     resizable: true
  //   },
  //   {
  //     headerName: "Price",
  //     field: "product.price",
  //     resizable: true
  //   }
  // ]
  return (
    <div style={{ width: "100%" }}>
      <Card>
        <Heading size="md">Product Details</Heading>
        {/* dkla */}
        <Stack spacing="5" padding="2">
          <Parameter title="ID" value={data.id} />
          <Parameter title="Name" value={data.name} />
          <Parameter title="Price" value={data.price} />
          <Parameter title="Description" value={data.description} />
          <Parameter title="Out of stock" value={JSON.stringify(data.outOfStock)} />
          <Parameter title="Max order quantity" value={data.maxOrderQuantity} />
          <Heading size="sm" textAlign="centre">Images</Heading>
          <SimpleGrid columns={2} spacing={10}>
  <Box><Image src={getCompleteUrl(data.imageUrl)} alt="Not Available" />Image 1</Box>
  <Box><Image src={getCompleteUrl(data.imageUrl2)} alt="Not Available" />Image 2</Box>
  <Box><Image src={getCompleteUrl(data.imageUrl3)} alt="Not Available" />Image 3</Box>
  <Box><Image src={getCompleteUrl(data.imageUrl4)} alt="Not Available" />Image 4</Box>
  <Box><Image src={getCompleteUrl(data.imageUrl5)} alt="Not Available" />Image 5</Box>
</SimpleGrid>
        </Stack>
        {/* <StatLabel>Dealer</StatLabel>
      <StatNumber>{data.dealer.name}</StatNumber> */}
        {/* <Table height={300} columnDefs={columnDefs} rowData={data.orderProducts} /> */}
      </Card>

      {/* {JSON.stringify(data)} */}
    </div>
  );
};
