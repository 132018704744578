import React, { useState } from 'react'
import OrderScreen from '../screens/OrdersScreen'
import { getDataSource } from '../api/tableFilter'
import crud from '../api/crud'
import { saveTextFile } from '../lib/file'
import { send } from '../api/calls'

export default function OrdersContainer() {
    // const { data, success, loading, refresh } = useApi("orders", "order")
    const [showArchived, setShowArchived] = useState(false)
    const toggleArchived = () => {
        setShowArchived((v) => !v)
    }



    const dataSource = getDataSource("order", `&filter=archived||eq||${showArchived}`)
    const handleExport = data => {
        const formatOrder = order => {
            const orderLine = `Order ID:, ${order.id},,, Dealer:, ${order.dealer.name} (${order.dealer.id})`
            const headers = "Product ID, Brand, Product Name, Price, Payment Method, Bulk Request Quantity, Quantity, Total"
            const createOrderProductLine = op => `${
                op.product.id},${
                op.product.brand.name},${
                op.product.name},${
                op.price},${
                op.paymentMethod},${
                op.bulkCount},${
                op.count},${
                op.price*op.count}`
            const itemsLines = order.orderProducts.map(createOrderProductLine)
           
            const totalValue = order.orderProducts.map(op => op.price*op.count).reduce((a, b) => a+b, 0)
            const total = `Total, , , , , , , ${totalValue}`
      

            return `${orderLine}\n${headers}\n${itemsLines.join("\n")}\n${total}`
        }

        const csvContent = data.map(formatOrder).join("\n \n")

        saveTextFile(csvContent, "orders-export.csv")
    }

    const changeState = (items, state) => {
        const accept = id => crud.update("order", { state, id })
        const promises = items.map(item => item.id).map(accept)
        return Promise.all(promises)
    }

    const handleAccept = items => {
        const notificationContent = {}
        notificationContent.title = "Order " + items[0].id
        notificationContent.content = "Accepted"
        notificationContent.users = [items[0].dealer.user.id]
        return changeState(items, 'accepted')
            .then(() => send(notificationContent))
    }
    const handleReject = items => {
        const notificationContent = {}
        notificationContent.title = "Order " + items[0].id
        notificationContent.content = "We will contact you shortly"
        notificationContent.users = [items[0].dealer.user.id]
        return changeState(items, 'rejected')
            .then(() => send(notificationContent))
    }
    // const clearState = items => changeState(items, 'none')

    const handleArchive = (items) => {
        const archive = id => crud.update("order", { archived: true, id })
        const promises = items.map(item => item.id).map(archive)
        return Promise.all(promises)
    }

    const handleUnArchive = (items) => {
        const archive = id => crud.update("order", { archived: false, id })
        const promises = items.map(item => item.id).map(archive)
        return Promise.all(promises)
    }


    const shouldDisplay = (check) => items => {
        return items.length === 1 && check(items[0]) ? undefined : "none"
    }


    const customActions = [
        { onClick: handleReject, title: "Reject", variantColor: "orange", getDisplay: shouldDisplay(item => item.state !== "rejected") },
        { onClick: handleAccept, title: "Accept", variantColor: 'green', getDisplay: shouldDisplay(item => item.state !== "accepted") },
        { onClick: handleArchive, title: "Archive", getDisplay: shouldDisplay(item => !item.archived) },
        { onClick: handleUnArchive, title: "Restore", getDisplay: shouldDisplay(item => item.archived) },
        { onClick: handleExport, title: "Export" },
    ]

    return (
        <OrderScreen
            key={showArchived}
            dataSource={dataSource}
            customActions={customActions}
            onToggleArchived={toggleArchived}
            showArchived={showArchived}
        />

    )
}
