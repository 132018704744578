import React from 'react'
import UsersScreen from "../screens/UsersScreen"
import crud from '../api/crud';
import { getDataSource } from '../api/tableFilter';
import { useAccess } from '../reducers/api/hooks';

export default function UsersContainer() {
    const dataSource = getDataSource("user")
    const handleCreate = (data) => {
        return crud.create("user", { ...data, roles: [data.roles] })
    }

    const handleDelete = (items) => {
        const promises = items.map(item => crud.deleteEntry("user", item.id))
        return Promise.all(promises)
    }

    const handleEdit = (data) => {
        return crud.update("user", { ...data, roles: [data.roles] })
    }

    const isAdmin = useAccess("admin")

    const handleActivate = (items) => {
        const activate = id => crud.update("user", { active: true, id })
        const promises = items.map(item => item.id).map(activate)
        return Promise.all(promises)
    }
    
    const handleDeactivate = (items) => {
        const deactivate = id => crud.update("user", { active: false, id })
        const promises = items.map(item => item.id).map(deactivate)
        return Promise.all(promises)
    }
    
    const shouldDisplay = (check) => items => {
        return items.length === 1 && check(items[0]) ? undefined : "none"
    }
    
    const customActions = [
        { onClick: handleActivate, title: "Activate", getDisplay: shouldDisplay(item => item.active !== true) },
        { onClick: handleDeactivate, title: "Deactivate", getDisplay: shouldDisplay(item => item.active !== false) },
    ]

    return (<UsersScreen
        dataSource={dataSource}
        onCreate={isAdmin ? handleCreate : undefined}
        onDelete={isAdmin ? handleDelete : undefined}
        onEdit={isAdmin ? handleEdit : undefined}
        customActions={customActions}
    />)
}
