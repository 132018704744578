import React, { useState } from "react";
import crud from "../api/crud";
import ServiceRequestsScreen from "../screens/ServiceRequestsScreen";
import { getDataSource } from "../api/tableFilter";

export default function ServiceRequestContainer() {
  //   const { data, success, loading, refresh } = useApi("services", "services");
  const [showArchived, setShowArchived] = useState(false)
  const toggleArchived = () => {
    setShowArchived((v) => !v)
  }

  const dataSource = getDataSource("service-request", `&filter=archived||eq||${showArchived}`)
 
  const handleCreate = data => {
    return crud.create("service-request", data);
  };

  const handleDelete = items => {
    const promises = items.map(item => crud.deleteEntry("serviceRequests", item.id));
    return Promise.all(promises);
  };

  const handleEdit = data => {
    return crud.update("serviceRequests", data);
  };



  const handleArchive = (items) => {
    const archive = id => crud.update("service-request", { archived: true, id })
    const promises = items.map(item => item.id).map(archive)
    return Promise.all(promises)
  }

  const handleUnArchive = (items) => {
    const archive = id => crud.update("service-request", { archived: false, id })
    const promises = items.map(item => item.id).map(archive)
    return Promise.all(promises)
  }

  const searchBrands = txt => {
    const mapItems = item => ({ value: item.id, label: item.name });
    return crud
      .getAll("brand", txt ? `?filter=name||cont||${txt}` : "")
      .then(data => data)
      .then(items => items.map(mapItems));
  };

  const shouldDisplay = (check) => items => {
    return items.length === 1 && check(items[0]) ? undefined : "none"
}

  const customActions = [
    { onClick: handleArchive, title: "Archive", getDisplay: shouldDisplay(item => !item.archived) },
    { onClick: handleUnArchive, title: "Restore", getDisplay: shouldDisplay(item => item.archived) },
  ]


  return (
    <ServiceRequestsScreen
      key={showArchived}
      dataSource={dataSource}
      customActions={customActions}
      onCreate={handleCreate}
      onDelete={handleDelete}
      onEdit={handleEdit}
      //   onRefresh={refresh}
      brandResolver={searchBrands}
      onToggleArchived={toggleArchived}
      showArchived={showArchived}
    />
  );
}
