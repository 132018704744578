import { api } from "./instance"

// AGM Data source model convert for crud api
export const getDataSource = (entity, extraQuery = "") => {
    const getRows = ({ startRow, endRow, sortModel, filterModel, successCallback, failCallback }) => {
        console.log("Get rows called", filterModel, sortModel)
        const query = createNestQuery({ startRow, endRow, sortModel, filterModel })
        api.get(`${entity}?${query}${extraQuery}`).then(resp => {
            const { data, total } = resp.data
            console.log(total, data)
            setTimeout(() => successCallback(data, total), 200)

        }).catch(() => failCallback())
    }
    return { getRows, destroy: () => console.log("Destroyed") }
}


const queryMapping = {
    "contains": "cont",
    "notContains": "excl",
    "equals": "eq",
    "notEqual": "excl",
    "startsWith": "starts",
    "endsWith": "ends",
    "": "cont"
}

const createNestQuery = ({ startRow, endRow, sortModel, filterModel }) => {
    const createPaginationQuery = (startRow, endRow) => `per_page=${endRow - startRow}&offset=${startRow}`
    // ToDO develop filter model and sort model
    const createSortQuery = (sortModel = []) =>
        sortModel.map(item => `&sort=${item.colId},${item.sort === "desc" ? "DESC" : "ASC"}`)
    const createFilterQuery = (filterModel) => {
        const fields = Object.keys(filterModel)
        const filters = []
        let q = ""
        // const getFilter = enty => 
        // Convert query to nest query

        const createNestFilter = (field, filter) => `${field}||${queryMapping[filter.type]}||${filter.filter}`

        fields.forEach(f => {
            const filter = { ...filterModel[f], field: f }
            console.log(filter.type)
            if (!filter.operator) {
                q += `&filter=${createNestFilter(f, filter)}`
            } else {
                console.log("Multi")
                const { condition1, condition2, operator } = filter
                q += `&filter=${
                    createNestFilter(f, condition1)
                    }&${
                    operator === "OR" ? "or=" : "filter="
                    }${
                    createNestFilter(f, condition2)
                    }`
            }


        })

        console.log(filters)
        return q
    }

    return `${
        createPaginationQuery(startRow, endRow)
        }${
        createSortQuery(sortModel)
        }${
        createFilterQuery(filterModel)
        }`

}