import axios from "axios";
import { BASE_URL } from "../config/config";

const createInstance = (baseURL, token) => {
    return axios.create({
        baseURL,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

}
export let api = createInstance(BASE_URL)

export const setApiToken = (token) => {
    api = createInstance(BASE_URL, token)
}


