
import React from 'react'
import crud from '../api/crud'
import { getDataSource } from '../api/tableFilter'
import PromotionScreen from '../screens/PromotionsScreen';
import { useAccess } from '../reducers/api/hooks';
import { sendMany } from '../api/calls';

export default function PromotionsContainer() {
    const dataSource = getDataSource("promotion");
    const handleCreate = (data) => {
        data = {
            ...data,
            product: {
                ...data.product,
                name: `Promotion:${data.title}`,
                brand: 1,
                imageUrl: data.imageUrl,
                price: 0,
                isPromotion: true,
                description: data.description,
            }
        }
        const notificationContent = {}
        notificationContent.title = "New Promotion"
        notificationContent.content = data.title
        return crud.create("promotion", data).then(() => sendMany(notificationContent))
    }

    const handleDelete = (items) => {
        const promises = items.map(item => crud.deleteEntry("promotion", item.id))
        return Promise.all(promises)
    }

    const handleEdit = (data) => {
        data = {
            ...data,
            product: {
                ...data.product,
                id: data.product.id,
                name: `Promotion:${data.title}`,
                imageUrl: data.imageUrl,
                brand: 1,
                price: 0,
                isPromotion: true,
                description: ""
            }
        }
        return crud.update("promotion", data)
    }

    const isAdmin = useAccess("admin")

    return (
        <PromotionScreen
            dataSource={dataSource}
            onCreate={isAdmin ? handleCreate : undefined}
            onDelete={isAdmin ? handleDelete : undefined}
            onEdit={isAdmin ? handleEdit : undefined}
        />
    )
}
